import React from "react"
import styled from "styled-components"
import Button from "./ui/buttons/Button"

const PaginationStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  a[disabled] {
    display: none;
  }

  a {
    margin-right: 12px;
  }

  [class*="${Button.name}"] {
    height: auto;
    padding: 13px;
    min-height: 0;
  }

  .page-numbers.dots {
    margin-right: 12px;
  }
`

export default function Pagination({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
}) {
  // make some variables
  const totalPages = Math.ceil(totalCount / pageSize)
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1
  const hasNextPage = nextPage <= totalPages
  const hasPrevPage = prevPage >= 1

  const midSize = 2
  const endSize = 1
  let dots = false

  return (
    <PaginationStyles>
      {hasPrevPage && (
        <Button title="Prev Page" to={`${base}/${prevPage}/`} color="pink">
          Previous
        </Button>
      )}

      {Array.from({ length: totalPages }).map((_, i) => {
        const n = i + 1

        if (currentPage === n) {
          return (
            <Button
              as="a"
              color="orange"
              size="small"
              to={`${base}/${n > 1 ? n : 1}/`}
              key={`page${i}`}
            >
              {n}
            </Button>
          )
        } else if (
          n <= endSize ||
          (currentPage &&
            n >= currentPage - midSize &&
            n <= currentPage + midSize) ||
          n > totalPages - endSize
        ) {
          dots = true
          return (
            <Button
              color="white"
              size="small"
              to={`${base}/${n > 1 ? n : 1}/`}
              key={`page${i}`}
            >
              {n}
            </Button>
          )
        } else if (dots) {
          dots = false
          return <div className="page-numbers dots">...</div>
        } else {
          return <></>
        }
      })}

      {hasNextPage && (
        <Button title="Next Page" to={`${base}/${nextPage}/`} color="pink">
          Next
        </Button>
      )}
    </PaginationStyles>
  )
}
