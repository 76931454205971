import React from "react"
import { graphql } from "gatsby"
import BlogTemplate from "../templates/blog-template"

export default function Blog({ data, pageContext }) {
  const page = data.allWpPage.nodes[0]
  const posts = data.allWpPost

  return (
    <BlogTemplate
      page={page}
      posts={posts.nodes}
      totalCount={posts.totalCount}
      {...pageContext}
    />
  )
}

export const pageQuery = graphql`
    query($skip: Int = 0, $pageSize: Int = 9) {
        allWpPage(filter: { isPostsPage: { eq: true } }, limit: 1) {
            nodes {
                uri
                slug
                title
                content
                seo {
                    canonical
                    cornerstone
                    focuskw
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphAuthor
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphImage {
                        localFile {
                            childImageSharp {
                                fixed {
                                    src
                                }
                            }
                        }
                    }
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    title
                    twitterDescription
                    twitterTitle
                    schema {
                        raw
                    }
                }
            }
        }
        allWpPost(
            sort: { fields: [date], order: DESC }
            limit: $pageSize
            skip: $skip
        ) {
            totalCount
            nodes {
                title
                excerpt
                slug
                date
                uri
                dateFormatted: date(formatString: "D/MM/YYYY")
                timeToRead
                author {
                    node {
                        firstName
                        lastName
                        name
                    }
                }
                categories {
                    nodes {
                        name
                    }
                }
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 480
                                    height: 388
                                    quality: 50
                                    placeholder: BLURRED
                                    transformOptions: {cropFocus: CENTER}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
