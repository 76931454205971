import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import styled from "styled-components"
import { motion } from "framer-motion"
import { wordCount, wordTruncate } from "@components/utils/helpers"
import { FontFamily } from "@styles/variables/FontFamily"
import Button from "@components/ui/buttons/Button"
import { TermPill } from "@styles/atoms/Pills"
import {
  breakpoints,
  breakpointsMedia
} from "@styles/variables/Breakpoint"
import { ArticleMeta } from "../parts/ArticleMeta"
import useMobile from "@components/utils/useMobile"
import classNames from "classnames"

export const BlogArticleStyled = styled(motion.article)`
  background-color: white;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  box-shadow: 0 4px 17px 2px rgba(0, 0, 0, 0.04);
  width: 389px;

  max-width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  text-decoration: none;
  
  ${breakpointsMedia(breakpoints.tabletWide)} {
    height: 503px;
  }

  a {
    text-decoration: none;
    width: 100%;
  }

  .inner {
    background-color: white;
    position: relative;
    padding: 17px 30px 0px 30px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 17px 30px 30px 30px;
    }
    
    a {
      text-decoration: none;
      width: 100%;
    }

    .title {
      font-size: 23px;
      font-weight: 500;
      line-height: 36px;
      color: var(--color-text);
      font-family: ${FontFamily.Heading};
      padding-bottom: 13px;

      &.lg {
        ${breakpointsMedia(breakpoints.tabletWide)} {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }

    .description {
      font-size: 17px;
      line-height: 27px;
    }
  }

  .description {
    border-top: 1px solid #f8f7f7;
  }

  .image-wrap {
    display: block;
    width: 100%;
    background: #f7a823;

    a {
      display: block;
      width: 100%;
      height: 100%;

      &.no-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .featured-image {
    height: 100%;
  }

  .button-wrap {
    padding: 20px;
    width: 100%;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 0;
      width: auto;
      position: absolute;
      bottom: 15px;
      left: 15px;
      right: 15px;

      &:before {
        content: "";
        display: block;
        background: linear-gradient(rgba(0, 0, 0, 0), white);
        height: 47px;
        position: absolute;
        bottom: 100%;
        width: 100%;
      }
    }
  }

  .pill {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }

  .author-meta .meta--data {
    &:after {
      content: "";
      background-color: var(--color-primary);
      width: 0;
      display: block;
      height: 1px;
      transition: width 0.75s;
    }
  }

  &:hover,
  &:active {
    .author-meta .meta--data {
      color: black;

      &:after {
        width: 100%;
      }
    }
  }
`

const desMotion = {
  rest: { opacity: 1, ease: "easeIn", duration: 0.2, type: "tween" },
  hover: {
    opacity: 0,
    height: 63,
    transition: {
      duration: 0.25,
      type: "tween",
      ease: "easeIn"
    }
  }
}

const imgMotion = {
  rest: {
    height: 200, ease: "easeIn", duration: 0.2, type: "tween",
    transform: 'scale(1.1)'
  },
  hover: {
    height: 287,
    transition: {
      duration: 0.25,
      type: "tween",
      ease: "easeIn"
    },
    transform: 'scale(1)'
  }
}

const buttonMotion = {
  rest: {
    opacity: 0,
    y: "100%",
    duration: 0.2
  },
  hover: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
      type: "tween",
      ease: "easeIn"
    }
  }
}

const ArticleModule = ({
                         title,
                         uri,
                         excerpt,
                         featuredImage,
                         categories,
                         dateFormatted,
                         timeToRead,
                         author
                       }) => {
  const { isMobile } = useMobile()
  const imageData = featuredImage?.node.localFile?.childImageSharp?.gatsbyImageData
  const bgImage = imageData && convertToBgImage(imageData)

  const largeTitle = title.length > 50

  const cleanExcerpt = excerpt?.replace(/&nbsp;/g, "")

  return (
    <BlogArticleStyled initial="rest" whileHover="hover" animate="rest">

      <motion.div className="image-wrap" variants={imgMotion}>
        <Link to={uri} className={classNames({ "no-image": !bgImage })}>
          {bgImage ? (
            <BackgroundImage className="featured-image" {...bgImage} />
          ) : <img src="/svgs/logo-fruit-white.svg" alt={"Passion Digital Logo"} width="100px" />}
        </Link>

      </motion.div>

      <div className="inner">
        {categories?.nodes && (
          <TermPill size="small" className="pill">
            {categories.nodes[0]?.name}
          </TermPill>
        )}
        <ArticleMeta>
          <div className="meta author-meta">
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fal"
                 data-icon="calendar" className="svg-inline--fa fa-calendar fa-w-14 meta--icon" role="img"
                 viewBox="0 0 448 512">
              <path fill="currentColor"
                    d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z" />
            </svg>
            <span className="meta--data">{author?.node.name}</span>
          </div>
          <div className="meta">
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fal"
                 data-icon="clock" className="svg-inline--fa fa-clock fa-w-16 meta--icon" role="img" viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" />
            </svg>
            <span className="meta--data">
              {timeToRead.replace("minutes", "mins")}
            </span>
          </div>
        </ArticleMeta>

        <Link to={uri} className={"title " + (largeTitle ? "lg" : "")}>
          {title}
        </Link>

        <motion.div
          className="description"
          variants={isMobile ? null : desMotion}
        >
          <div
            className="dark"
            dangerouslySetInnerHTML={{
              __html: wordTruncate(cleanExcerpt, 30 - wordCount(title), true)
            }}
          />
        </motion.div>
      </div>
      {isMobile ? (
        <div className="button-wrap">
          <Button to={uri} color="orange" size="large">
            Read article
          </Button>
        </div>
      ) : (
        <motion.div
          className="button-wrap"
          as={motion.div}
          variants={buttonMotion}
        >
          <Button to={uri} color="orange" size="large">
            Read article
          </Button>
        </motion.div>
      )}
    </BlogArticleStyled>
  )
}

export default ArticleModule
