import React from "react"
import Seo from "@components/Seo"
import Helmet from "react-helmet"
import ArticleModule from "@components/modules/ArticleModule"
import Pagination from "@components/Pagination"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Button from "@components/ui/buttons/Button"
import { bool } from "prop-types"
import Heading from "@components/ui/Heading"
import Spacer from "@components/ui/Spacer"

const TitleWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

const SubTitleWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    flex-direction: row;
  }

  a + h2 {
    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-left: 35px;
    }
  }

  a {
    order: 2;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      order: 1;
    }
  }

  h2 {
    order: 1;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      order: 2;
    }
  }
`

const BlogHeader = styled.div`
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 30px;
  margin-bottom: 48px;

  h1 {
  }
`

const ArticleGridStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;

  ${breakpointsMedia(breakpoints.tablet)} {
    grid-template-columns: 1fr 1fr;
  }

  ${breakpointsMedia(breakpoints.desktopSM)} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  > [class*="${ArticleModule.name}"] {
    width: auto;
    max-width: 389px;
    margin: auto;
  }
`

export default function BlogTemplate({
  page,
  posts,
  totalCount,
  currentPage,
  categoryName,
  authorName,
  canonical,
  skip,
  baseURL,
  backToBlog,
  headerChildren,
}) {
  const pageSize = parseInt(process.env.GATSBY_POST_SIZE) || 9
  const totalPages = Math.ceil(totalCount / pageSize)

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "short blog bg-triangles",
        }}
      />
      <Seo {...page.seo} customCanonical={canonical} />
      <div className="container">
        <BlogHeader>
          <TitleWrap>
            <Spacer>
              <Heading underlined>Our digital marketing feed</Heading>
            </Spacer>

            {currentPage > 1 && (
              <Heading node="h3">
                Page {currentPage} of {totalPages}
              </Heading>
            )}
          </TitleWrap>

          <SubTitleWrap>
            {backToBlog && (
              <Button color="white" size={"small"} to={"/blog"}>
                Back to blog
              </Button>
            )}

            {categoryName && (
              <Heading node="h2" variant="h3">
                Category:{" "}
                <span style={{ fontWeight: "300" }}>{categoryName}</span>
              </Heading>
            )}

            {authorName && (
              <Heading node="h2" variant="h3">
                Author: <span style={{ fontWeight: "300" }}>{authorName}</span>
              </Heading>
            )}
          </SubTitleWrap>

          {headerChildren}
        </BlogHeader>

        <ArticleGridStyled>
          {posts.map((node, key) => (
            <ArticleModule key={key} {...node} />
          ))}
        </ArticleGridStyled>

        {totalPages > 1 ? (
          <Pagination
            pageSize={pageSize}
            totalCount={totalCount}
            currentPage={currentPage || 1}
            skip={skip}
            base={`${page.uri}page`}
          />
        ) : (
          <div style={{ height: "80px" }} />
        )}
      </div>
    </>
  )
}

BlogTemplate.propTypes = {
  backToBlog: bool,
}

BlogTemplate.defaultProp = {
  backToBlog: false,
}
